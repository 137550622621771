import * as styles from './floating-button.module.css';

import { Link, graphql, useStaticQuery } from 'gatsby';

import Lottie from 'react-lottie';
import React from 'react';

const FloatingButton = (props) => {

    const queryResult = useStaticQuery(query);
    const { floatingButton } = queryResult.cms;

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: floatingButton.lottieData,
        rendererSettings: {
            preserveAspectRatio: `xMidYMid slice`
        }
    }

    return (
        <div className={`${styles.floatingButton}`}>
            {
                props.navigateToForm ?
                    <a href={props.navigateToForm}>
                        <Lottie options={lottieOptions} width="100%" height="100%"></Lottie>
                    </a>
                :
                    <Link to="/contact-us">
                        <Lottie options={lottieOptions} width="100%" height="100%"></Lottie>
                    </Link>
            }
        </div>
    )

}

export default FloatingButton;

const query = graphql`
{
  cms {
    floatingButton {
      lottieData
    }
  }
}
`;